import React, {Component} from 'react';
import {Link} from '@reach/router';
import '../Css/Nav.css';
import {useMediaQuery} from "react-responsive";
import logo from "./../Image/EDS - Logo Air Technology.png"

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNavMobile: false
        };
    }



    render() {
        const DeviceSize = ({children}) => {
            var isDesktop = useMediaQuery({minWidth: 992});
            var isTablet = useMediaQuery({minWidth: 768, maxWidth: 991});
            var isNotMobile = useMediaQuery({minWidth: 768});
            if (isDesktop) {
                return isDesktop ? children : null
            } else if (isTablet) {
                return isTablet ? children : null;
            } else {
                return isNotMobile ? children : null
            }

        };
        const Mobile = ({children}) => {
            var isMobile = useMediaQuery({maxWidth: 767});
            return isMobile ? children : null
        };

        return (
            <div>
                <DeviceSize>
                    <nav className=" nav-layout navbar navbar-expand">
                        <div className="div-fluid container-fluid">
                            <Link to="/" >
                              <img alt="logo" className="logo-layout" src={logo}/>
                            </Link>
                            <div className=" navbar-nav ml-auto">
                                <Link className="card-text btn text-white" to="/">
                                    <h5 className=" text-left">Home</h5>
                                </Link>
                                <Link className=" card-text btn text-white" to="/aboutus">
                                    <h5 className="  text-left">Over EDS</h5>
                                </Link>
                                <Link className=" card-text btn text-white" to="/werkwijze">
                                    <h5 className="  text-left">Werkwijze</h5>
                                </Link>
                                <Link className="card-text btn text-white" to="/contact">
                                    <h5 className=" text-left">Contact</h5>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </DeviceSize>
                <Mobile>
                    <div className="pos-f-t bg-success">
                        {this.state.showNavMobile && <div className="nav-layout navbar-dark">
                            <Link to="/" >
                                <img alt="logo" className="logo-layout" src={logo}/>
                            </Link>
                            <div className=" navbar-nav ml-auto">
                                <Link className="card-text btn text-white" to="/">
                                    <h5 className=" text-left">Home</h5>
                                </Link>
                                <Link className=" card-text btn text-white" to="/aboutus">
                                    <h5 className="  text-left">Over EDS</h5>
                                </Link>
                                <Link className=" card-text btn text-white" to="/werkwijze">
                                    <h5 className="  text-left">Werkwijze</h5>
                                </Link>
                                <Link className="card-text btn text-white" to="/contact">
                                    <h5 className=" text-left">Contact</h5>
                                </Link>
                            </div>
                        </div>
                        }
                        <nav className="navbar navbar-dark nav-layout">
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarToggleExternalContent"
                                    aria-controls="navbarToggleExternalContent"
                                    aria-expanded="true" aria-label="Toggle navigation" onClick={() => {
                                this.setState({showNavMobile: !this.state.showNavMobile})
                            }}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </nav>
                    </div>
                </Mobile>
            </div>

        );
    }
}

export default NavigationBar;
