import React, {Component} from 'react';
import '../Css/Werkwijze.css';
import Footer from "../Nav/Footer";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: "Inventarisatie",
        };
    }

    render() {


        return (
            <div className="row carousel-layout">
                <div className="col-lg-12">
                    <div className="image-wisselende-inventarisatie  w-100 row">
                        <div className="div-text-layout-quickscan col-lg-8">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">

                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">
                                    <h3 className="quickscan-kopje">Quickscan</h3>
                                    Als eerste contact met onze klant bieden we graag een Quickscan aan. Naast alle voordelen van de quickscan,
                                    is dit voor ons een volwaardige introductie van uw bedrijf. Wij maken kennis met uw specialisme, en uw perslucht systeem.
                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-title-layout-inventarisatie col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Inventarisatie</h2></div>
                        <div className="div-text-layout-inventarisatie col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">De Quickscan begint bij inventarisatie van uw persluchtsysteem. Welke informatie is reeds beschikbaar over het perslucht systeem?
                                Door de reeds beschikbare data te analyseren maken we een vliegende start met de
                                Quickscan.
                            </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-inventarisatie col-xl-4"></div>
                    </div>
                </div>
                <div className="col-lg-12 ">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div className="col-lg-12">
                    <div className="image-wisselende-overzicht  w-100 row">
                        <div className="div-title-layout-overzicht col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Overzicht</h2></div>
                        <div className="div-text-layout-overzicht col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">Door het perslucht systeem in hoofdlijnen in kaart te brengen creëren we overzicht.
                                    Met dit overzicht kunnen we kritische locaties uitlichten en de meetlocaties bepalen.
                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-overzicht col-xl-2 "></div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div className="col-lg-12">
                    <div className="image-wisselende-meten  w-100 row">
                        <div className="div-title-layout-meten col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Meten</h2></div>
                        <div className="div-text-layout-meten col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">Meten is weten. We installeren tijdelijk ons mobiele dashboard. Vermogensopnemers meten de opwekking en onderlinge regeling van compressoren.
                                    Daarnaast maken we gebruik van bi-directionele sensoren die debiet, druk en tempratuur van perslucht meten.
                                    De sensoren staan in verbinding met het dashboard voor live monitoring.

                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-meten col-xl-2  "></div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div className="col-lg-12">
                    <div className="image-wisselende-analyseren  w-100 row">
                        <div className="div-title-layout-analyseren col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Analyseren</h2></div>
                        <div className="div-text-layout-analyseren col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">De data van de metingen worden verwerkt en gevisualiseerd. Tijdens de analyse zoeken we
                                    naar verbanden tussen het productieproces en perslucht verbruik. Ook onderzoeken we welke processen
                                    de grootste invloed hebben op het energiegebruik. Op deze wijze onderzoeken wij doelgericht de grootst mogelijke besparingen.
                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-analyseren col-xl-2  "></div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div className="col-lg-12">
                    <div className="image-wisselende-engineering  w-100 row">
                        <div className="div-title-layout-engineering col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Engineering</h2></div>
                        <div className="div-text-layout-engineering col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">Elk perslucht systeem is uniek!
                                    Afhankelijk van het perslucht systeem de metingen en analyse worden verschillende potenties tot besparing vergeleken.
                                    De opties worden geselecteerd en vergeleken aan de hand van uw prioriteiten.

                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-engineering col-xl-2  "></div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div className="col-lg-12">
                    <div className="image-wisselende-rapportage  w-100 row">
                        <div className="div-title-layout-rapportage col-lg-12 text-left text-white">
                            <h2 className="ml-5 title-kopjes">Rapportage</h2></div>
                        <div className="div-text-layout-rapportage col-lg-12">
                            <div className="row">
                                <h5 className="col-lg-1 col ml-5 mr-5 mt-2 mb-2">
                                </h5>
                                <h5 className="col-lg-9 ml-n5 mr-5 mt-2 mb-2">De gehele Quickscan wordt gedocumenteerd in een rapport.
                                    Na afloop van de Quickscan, heeft u overzicht in uw perslucht systeem
                                    aan de hand van detailgegevens van uw perslucht installatie. U heeft inzicht in
                                    perslucht opwekking, distributie en gebruik. De analyse biedt inzicht in de relatie tussen perslucht gebruik en
                                    productie. Daarnaast ontvangt u een advies voor optimalisatie van uw perslucht systeem.
                                </h5>
                                <h5 className="col-lg-2 ml-5 mr-5 mt-2 mb-2">
                                </h5>
                            </div>
                        </div>
                        <div className="div-logo-layout-rapportage col-xl-2  "></div>
                    </div>
                </div>


                <Footer> </Footer>
            </div>

        )
    }
}


export default Home;