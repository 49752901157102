import React, {Component} from 'react';
import '../Css/Home.css';


class Home extends Component {


    render() {


        return (
            <div>
                <div className="contact-image ">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-12">
                                <div className="card-body">
                                    <section className="form-group">
                                        <div className="card-contact text-dark  mb-3 ">
                                            <div className="card-body  text-center">
                                                <h1>Contact</h1>
                                                <br/>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <h3 className="text-layout">Kantoor</h3>
                                                        <p>Adres: Ellermanstraat 33
                                                            1114 AK Amsterdam</p>
                                                        <p>Tel: +31 (0) 20 358 5066</p>
                                                        <p> Email: info@edsinnovation.com</p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <h3 className="text-layout">Sales</h3>
                                                        <p> Naam: Markus Rahusen</p>
                                                        <p>Tel: +31 (0) 6 2553 3840</p>
                                                        <p> Email: mark@edsinnovation.com</p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <h3 className="text-layout">Engineering</h3>
                                                        <p> Naam: Thijs de Vries</p>
                                                        <p>Tel: +31 (0) 6 57417504</p>
                                                        <p> Email: thijs.de.vries@edsinnovation.com </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="form-group text-right mb-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}


export default Home;