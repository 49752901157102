import React, {Component} from 'react';
import '../Css/Home.css';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {

    }


    render() {

        return (

                <div className="aboutus-image">
                    <div className="container">
                        <div className="row justify-content-between ">
                            <div className="col-lg-12 ">
                                <div className="card-body ">
                                    <section className="form-group ">
                                        <div className="card mb-3">
                                            <div className="card-body text-center">
                                                <h1 className="title-aboutus-layout">Over EDS-Air Technology</h1>
                                                <br/>
                                                <h3 className="title2-aboutus-layout">Together we reduce costs!</h3>
                                                <br/>
                                                <p className="text1-aboutus-layout"> EDS-Air Technology wordt gedreven door innovatie en verduurzaming van de industrie. Air Technology is een engineering bureau voor perslucht systemen. Samen met onze klant onderzoeken wij de potentie tot energiebesparing. Met een mobiel dashboard creëren we inzicht in perslucht opwekking, distributie, gebruik en besparingspotentie.
                                                    Het creëren van inzicht en overzicht in een perslucht systemen is relevant voor elke bedrijf wat met perslucht werkt. Wanneer duidelijk is waar de grootste besparingen liggen voor uw perslucht systeem wordt er een besparingsplan opgesteld.
                                                    Met de prioriteitstelling van de klant zoeken wij naar de meeste geschikte oplossingen. Door de expert in huis te halen, worden niet alleen kosten en energie bespaart maar behoudt u ook focus op uw eigen specialisme.
                                                </p>
                                                <h3 className="title3-aboutus-layout-missie">Missie</h3>
                                                <p className="text2-aboutus-layout"> Gezamenlijk verduurzamen van de industrie van de toekomst, waarin
                                                    het energie gebruik van perslucht is geminimaliseerd en
                                                    geoptimaliseerd.</p>
                                                <br/>
                                                <h3 className="title3-aboutus-layout-visie"> Visie</h3>
                                                <p className="text3-aboutus-layout"> Gezamenlijk bouwen aan een de duurzame industrie Verduurzamen van de
                                                    industrie
                                                    Door middel van innovatie, gezamenlijk
                                                    Versnellen van de verduurzaming van de industrie </p>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="form-group text-right mb-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        )
    }
}

export default AboutUs;