import React, {Component} from 'react';
import './App.css';
import {Router} from "@reach/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Dashboard/Home";
import Werkwijze from "./Dashboard/Werkwijze";
import Contact from "./Dashboard/Contact";
import AboutUs from "./Disclaimer/AboutUs";
import NavigationBar from "./Nav/NavigationBar";
import * as firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyAJDqPJqdWhfxsyr4OTZbM-cLePi_MULJU",
    authDomain: "new-eds-air-tech.firebaseapp.com",
    databaseURL: "https://new-eds-air-tech.firebaseio.com",
    projectId: "new-eds-air-tech",
    storageBucket: "new-eds-air-tech.appspot.com",
    messagingSenderId: "818391753191",
    appId: "1:818391753191:web:0a0925c126f208f6e99d42"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        return (
            <div className="App">
                <header>
                    <NavigationBar/>
                </header>
                <Router className="">
                    <Home path="/" />
                    <Werkwijze path="/werkwijze"/>
                    <Contact path="/contact"/>
                    <AboutUs path="/aboutus"/>
                </Router>
            </div>
        );
    }

}

export default App;
