import React, {Component} from 'react';
import {Link} from '@reach/router';
import '../Css/Nav.css';
import {FaLinkedinIn,FaGlobe} from 'react-icons/fa';
import {useMediaQuery} from "react-responsive";
import logo from "../Image/EDS - Logo Air Technology.png";

class Footer extends Component {

    linkOpen(link) {
        window.open(link)
    }

    render() {
        const DeviceSize = ({children}) => {
            var isDesktop = useMediaQuery({minWidth: 992});
            var isTablet = useMediaQuery({minWidth: 768, maxWidth: 991});
            var isNotMobile = useMediaQuery({minWidth: 768});
            if (isDesktop) {
                return isDesktop ? children : null
            } else if (isTablet) {
                return isTablet ? children : null;
            } else {
                return isNotMobile ? children : null
            }

        };

        return (
            <div className="col-lg-12 ">
                <DeviceSize>
                    <footer className="py-5 footer-layout">
                        <div className="container">
                            <nav className="navbar-dark align-items-end footer-layout">
                                <div className="div-fluid row">
                                    <Link to="/" className="navbar-brand text-left col-lg-5">
                                        <img alt="logo" className="logo-layout-footer" src={logo}/>
                                    </Link>

                                    <FaGlobe className=" mr-2 h-100 w-auto text-platforms" onClick={() => {
                                        this.linkOpen("https://edscasting.com/")
                                    }}/>
                                    <FaLinkedinIn className="mr-2  h-100 w-auto text-platforms" onClick={() => {
                                        this.linkOpen("https://www.linkedin.com/company/43276568")
                                    }}/>
                                </div>
                            </nav>
                            <p className="m-0 text-center text-white">Copyright &copy; Your Website 2020</p>
                        </div>
                    </footer>
                </DeviceSize>
            </div>

        );
    }
}

export default Footer;
