import React, {Component} from 'react';
import '../Css/Home.css';
import Footer from "../Nav/Footer";
import {Carousel} from "react-bootstrap";
import Slide1 from "./../Image/Home-1.jpg";
import Slide2 from "./../Image/Home-2.jpg";
import Slide3 from "./../Image/Slide3.PNG";
import Slide4 from "./../Image/Slide4.PNG";


class Home extends Component {


    render() {

        return (
            <div className="row carousel-layout">
                <Carousel className="col-lg-12">
                    <Carousel.Item>
                        <img alt="logo" src={Slide1}
                             className="image-layout col-lg-12 w-100 ">
                        </img>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="logo" src={Slide2}
                             className="image-layout col-lg-12 w-100 ">
                        </img>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="logo" src={Slide3}
                             className="image-layout col-lg-12 w-100 ">
                        </img>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="logo" src={Slide4}
                             className="image-layout col-lg-12  w-100">
                        </img>
                    </Carousel.Item>
                </Carousel>
                <Footer> </Footer>
            </div>

        )
    }
}


export default Home;